'use client';
import { createContext, useState } from 'react';

export const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  return (
    <LoadingContext.Provider
      value={{ loading, setLoading, message, setMessage }}
    >
      {children}
    </LoadingContext.Provider>
  );
};
