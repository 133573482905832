'use client';
import { Backdrop, CircularProgress, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { LoadingContext } from './LoadingContext';

const LoadingBackdrop = () => {
  const { loading, message } = useContext(LoadingContext);

  return (
    <Backdrop sx={{ zIndex: 9007199254740991 }} open={loading}>
      <Stack spacing={2} alignItems="center">
        <CircularProgress sx={{ color: '#ffffff' }} />
        {message && (
          <Typography sx={{ marginTop: 2, color: '#ffffff' }}>
            {message}
          </Typography>
        )}
      </Stack>
      {/* Display the message if it exists */}
    </Backdrop>
  );
};

export default LoadingBackdrop;
