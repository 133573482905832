import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { HYDRATE, createWrapper } from 'next-redux-wrapper';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Use storage for persistence
import alertSliceReducer from './alertSlice';
import authReducer from './authSlice';

const rootReducer = combineReducers({
  authvalues: authReducer,
  alertpopup: alertSliceReducer,
});

const masterReducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state,
      ...action.payload,
    };
    if (state.authvalues) {
      nextState.authvalues = {
        ...state.authvalues,
        ...action.payload.authvalues,
      };
    }
    return nextState;
  }
  return rootReducer(state, action);
};

const makeConfiguredStore = reducer =>
  configureStore({
    reducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  });

export const makeStore = () => {
  const isServer = typeof window === 'undefined';

  if (isServer) {
    return makeConfiguredStore(masterReducer);
  } else {
    const persistConfig = {
      key: 'nextjs',
      whitelist: ['authvalues', 'alertpopup'],
      storage,
    };
    const persistedReducer = persistReducer(persistConfig, masterReducer);
    const store = makeConfiguredStore(persistedReducer);
    store.__persistor = persistStore(store); // Persistor creation
    return store;
  }
};

export const store = makeStore();

export const clientStore = makeStore();

export const wrapper = createWrapper(makeStore);
