// 'use client';

// import { store } from '@/store/store'; // Adjust the import based on your store's file location
// import { useRef } from 'react';
// import { Provider } from 'react-redux';
// import { persistStore } from 'redux-persist';
// import { PersistGate } from 'redux-persist/integration/react';

// export default function ReduxProvider({ children }) {
//   const persistorRef = useRef();
//   if (!persistorRef.current) {
//     // Create the persistor instance the first time this renders
//     // Note: We're using the existing store instance imported directly,
//     // so no need to recreate it with makeStore() in this context
//     persistorRef.current = persistStore(store);
//   }

//   return (
//     <Provider store={store}>
//       <PersistGate loading={null} persistor={persistorRef.current}>
//         {children}
//       </PersistGate>
//     </Provider>
//   );
// }

// 'use client';

// import { makeStore } from '@/store/store';
// import { useRef } from 'react';
// import { Provider } from 'react-redux';

// export default function ReduxProvider({ children, pageProps }) {
//   const storeRef = useRef();
//   if (!storeRef.current) {
//     // Create the store instance the first time this renders
//     storeRef.current = makeStore();
//   }

//   return <Provider store={storeRef.current}>{children}</Provider>;
// }

// Assuming this import is correct and points to where your store is configured

'use client';
import LogoLoader from '@/components/loadingComponets/LogoLoader';
import { useRef } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store } from './store';

// let store; // Declared outside to make it accessible in a controlled manner

// export function getStore() {
//   return store; // Accessor method for the store
// }

export default function ReduxProvider({ children }) {
  const persistorRef = useRef();

  if (!persistorRef.current) {
    persistorRef.current = store.__persistor; // Use the persistor from the store
  }

  return (
    <Provider store={store}>
      <PersistGate loading={<LogoLoader />} persistor={persistorRef.current}>
        {children}
      </PersistGate>
    </Provider>
  );
}
